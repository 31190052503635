

import Bg from "../../../public/index/BG_PILLS.webp"
import P1 from "../../../public/index/avatar-landing-augencore-hero.png"
import P2 from "../../../public/index/avatar-landing-earthen-hero.png"
import P3 from "../../../public/index/avatar-landing-kathari-hero.png"
import P4 from "../../../public/index/avatar-landing-marcolian-hero.png"
import P5 from "../../../public/index/avatar-landing-shroud-hero.png"
import P6 from "../../../public/index/avatar-landing-augencore-article-1.png"
import Link from "next/link";
import useMarketplaceChain from "../../../hooks/useMarketplaceChain";
import {Box, Flex,Text} from "../../primitives";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import {Autoplay, EffectFade, Navigation, Pagination} from 'swiper/modules'
import Image from "next/image";
type TabValue = 'collections' | 'mints'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
    ComponentPropsWithoutRef,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useRouter } from "next/router";
import supportedChains from 'utils/chains'
import {
    useTrendingCollections,
    useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import {
    CollectionsSortingOption,
} from 'components/common/CollectionsTimeDropdown'
import {
    MintsSortingOption,
} from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'

type Props = InferGetServerSidePropsType<typeof getServerSideProps>


const Banner:NextPage = ()=> {
    const router = useRouter()
    const { routePrefix } = useMarketplaceChain()
    // not sure if there is a better way to fix this
    const { theme: nextTheme } = useTheme()
    const [theme, setTheme] = useState<string | null>(null)
    useEffect(() => {
        if (nextTheme) {
            setTheme(nextTheme)
        }
    }, [nextTheme])

    const isSSR = typeof window === 'undefined'
    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

    const [tab, setTab] = useState<TabValue>('collections')
    const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

    const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')

    let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
        limit: 20,
        period: sortByPeriod,
        type: 'any',
    }

    const { chain, switchCurrentChain } = useContext(ChainContext)

    useEffect(() => {
        if (router.query.chain) {
            let chainIndex: number | undefined
            for (let i = 0; i < supportedChains.length; i++) {
                if (supportedChains[i].routePrefix == router.query.chain) {
                    chainIndex = supportedChains[i].id
                }
            }
            if (chainIndex !== -1 && chainIndex) {
                switchCurrentChain(chainIndex)
            }
        }
    }, [router.query])

    const {
        data: trendingCollections,
        isValidating: isTrendingCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'volume',
            period: sortByTime,
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const {
        data: featuredCollections,
        isValidating: isFeaturedCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'sales',
            period: '24h',
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const { data: trendingMints, isValidating: isTrendingMintsValidating } =
        useTrendingMints({ ...mintsQuery }, chain.id, {
            fallbackData: "",
            keepPreviousData: true,
        })
    let volumeKey: ComponentPropsWithoutRef<
        typeof CollectionRankingsTable
    >['volumeKey'] = '1day'

    switch (sortByTime) {
        case '30d':
            volumeKey = '30day'
            break
        case '7d':
            volumeKey = '7day'
            break
        case '24h':
            volumeKey = '1day'
            break
    }
    console.log("featuredCollections", featuredCollections)
    // @ts-ignore
    return (
        <>
            <Box css={{
                width: "100%",
                // background:"radial-gradient(30% 41% at 107% 13%, #1a240a 10%,rgba(15, 129, 255, 0.24) 20%);"
            }}>
                <Box css={{
                    width: "80%",
                    margin: "0 auto",
                    border: "1px solid rgb(15 129 255 / 34%)",

                }}>
                    <Swiper
                        spaceBetween={30}
                        effect={'fade'}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[EffectFade,Autoplay]}
                        className="mySwiper"
                        style={{
                            height:"100%"
                        }}
                    >
                        <SwiperSlide>
                            {/*sm: '(min-width: 600px)',*/}
                            {/*md: '(min-width: 900px)',*/}
                            {/*lg: '(min-width: 1200px)',*/}
                            {/*xl: '(min-width: 1820px)',*/}
                            {/*bp300: '(min-width: 300px)',*/}
                            {/*bp400: '(min-width: 400px)',*/}
                            {/*bp500: '(min-width: 500px)',*/}
                            {/*bp600: '(min-width: 600px)',*/}
                            {/*bp700: '(min-width: 700px)',*/}
                            {/*bp800: '(min-width: 800px)',*/}
                            {/*bp900: '(min-width: 900px)',*/}
                            {/*bp1000: '(min-width: 1000px)',*/}
                            {/*bp1100: '(min-width: 1100px)',*/}
                            {/*bp1200: '(min-width: 1200px)',*/}
                            {/*bp1300: '(min-width: 1300px)',*/}
                            {/*bp1400: '(min-width: 1400px)',*/}
                            {/*bp1500: '(min-width: 1500px)',*/}
                            <Box css={{
                                background:"linear-gradient(135deg, #0f0c29, #302b63, #24243e)",
                                height:"100%"
                            }}>
                             <Flex align="center" css={{

                             }}>

                                 <Box css={{
                                     width:"60%"
                                 }}>
                                     <Image src={P1} alt="" width={790}/>
                                 </Box>
                                 <Box css={{
                                     display:"flex",
                                     flexDirection:"column",
                                     textAlign:"center"
                                 }}>
                                     <Text css={{
                                         color:"hsla(0, 0%, 100%, .6)",
                                         fontSize:"32px"
                                     }}>
                                         PARALLEL
                                     </Text>
                                     <Text css={{
                                         color:"#0F81FF",
                                         fontSize:"84px"
                                     }}>
                                         AVATARS
                                     </Text>
                                     <Box css={{
                                         marginTop:"$4"
                                     }}>
                                         <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`} style={{
                                             backgroundColor: "black",
                                             padding: "20px"
                                         }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>Explore Avatars</span>
                                         </Link>
                                         <Link href={`/${routePrefix}/collections/trending`} style={{
                                             backgroundColor: "black",
                                             padding: "20px",
                                             marginLeft: "30px"
                                         }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>View collection </span>
                                         </Link>
                                     </Box>
                                 </Box>
                             </Flex>
                            </Box>

                        </SwiperSlide>
                        <SwiperSlide>
                            <Box css={{
                                background:"linear-gradient(135deg, #0f0c29, #302b63, #24243e)",
                                height:"100%",

                            }}>
                                <Flex align="center" css={{

                                }}>
                                <Box css={{
                                    width:"60%"
                                }}>
                                    <Image src={P2} alt="" width={790}/>
                                </Box>
                                <Box css={{
                                    display:"flex",
                                    flexDirection:"column",
                                    textAlign:"center"
                                }}>
                                    <Text css={{
                                        color:"hsla(0, 0%, 100%, .6)",
                                        fontSize:"32px"
                                    }}>
                                        PARALLEL
                                    </Text>
                                    <Text css={{
                                        color:"#0F81FF",
                                        fontSize:"84px"
                                    }}>
                                        AVATARS
                                    </Text>
                                    <Box css={{
                                        marginTop:"$4"
                                    }}>
                                        <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`} style={{
                                            backgroundColor: "black",
                                            padding: "20px"
                                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>Explore Avatars</span>
                                        </Link>
                                        <Link href={`/${routePrefix}/collections/trending`} style={{
                                            backgroundColor: "black",
                                            padding: "20px",
                                            marginLeft: "30px"
                                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>View collection</span>
                                        </Link>
                                    </Box>
                                </Box>
                                </Flex>
                            </Box>

                        </SwiperSlide>
                        <SwiperSlide>
                            <Box css={{
                                background:"linear-gradient(135deg, #0f0c29, #302b63, #24243e)",
                                height:"100%",

                            }}>
                                <Flex align="center" css={{

                                }}>
                                <Box css={{
                                    width:"60%"
                                }}>
                                    <Image src={P3} alt="" width={790}/>
                                </Box>
                                <Box css={{
                                    display:"flex",
                                    flexDirection:"column",
                                    textAlign:"center"
                                }}>
                                    <Text css={{
                                        color:"hsla(0, 0%, 100%, .6)",
                                        fontSize:"32px"
                                    }}>
                                        PARALLEL
                                    </Text>
                                    <Text css={{
                                        color:"#0F81FF",
                                        fontSize:"84px"
                                    }}>
                                        AVATARS
                                    </Text>
                                    <Box css={{
                                        marginTop:"$4"
                                    }}>
                                        <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`} style={{
                                            backgroundColor: "black",
                                            padding: "20px"
                                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>Explore Avatars</span>
                                        </Link>
                                        <Link href={`/${routePrefix}/collections/trending`} style={{
                                            backgroundColor: "black",
                                            padding: "20px",
                                            marginLeft: "30px"
                                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>View collection</span>
                                        </Link>
                                    </Box>
                                </Box>
                                </Flex>
                            </Box>

                        </SwiperSlide>
                        <SwiperSlide>
                            <Box css={{
                                background:"linear-gradient(135deg, #0f0c29, #302b63, #24243e)",
                                height:"100%",

                            }}>
                                <Flex align="center" css={{

                                }}>
                                <Box css={{
                                    width:"60%"
                                }}>
                                    <Image src={P4} alt="" width={790}/>
                                </Box>
                                <Box css={{
                                    display:"flex",
                                    flexDirection:"column",
                                    textAlign:"center"
                                }}>
                                    <Text css={{
                                        color:"hsla(0, 0%, 100%, .6)",
                                        fontSize:"32px"
                                    }}>
                                        PARALLEL
                                    </Text>
                                    <Text css={{
                                        color:"#0F81FF",
                                        fontSize:"84px"
                                    }}>
                                        AVATARS
                                    </Text>
                                    <Box css={{
                                        marginTop:"$4"
                                    }}>
                                        <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`} style={{
                                            backgroundColor: "black",
                                            padding: "20px"
                                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>Explore Avatars</span>
                                        </Link>
                                        <Link href={`/${routePrefix}/collections/trending`} style={{
                                            backgroundColor: "black",
                                            padding: "20px",
                                            marginLeft: "30px"
                                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>View collection</span>
                                        </Link>
                                    </Box>
                                </Box>
                                </Flex>
                            </Box>

                        </SwiperSlide>
                        <SwiperSlide>
                            <Box css={{
                                background:"linear-gradient(135deg, #0f0c29, #302b63, #24243e)",
                                height:"100%",

                            }}>
                                <Flex align="center" css={{

                                }}>
                                <Box css={{
                                    width:"60%"
                                }}>
                                    <Image src={P5} alt="" width={790}/>
                                </Box>
                                <Box css={{
                                    display:"flex",
                                    flexDirection:"column",
                                    textAlign:"center"
                                }}>
                                    <Text css={{
                                        color:"hsla(0, 0%, 100%, .6)",
                                        fontSize:"32px"
                                    }}>
                                        PARALLEL
                                    </Text>
                                    <Text css={{
                                        color:"#0F81FF",
                                        fontSize:"84px"
                                    }}>
                                        AVATARS
                                    </Text>
                                    <Box css={{
                                        marginTop:"$4"
                                    }}>
                                        <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`} style={{
                                            backgroundColor: "black",
                                            padding: "20px"
                                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>Explore Avatars</span>
                                        </Link>
                                        <Link href={`/${routePrefix}/collections/trending`} style={{
                                            backgroundColor: "black",
                                            padding: "20px",
                                            marginLeft: "30px"
                                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>View collection</span>
                                        </Link>
                                    </Box>
                                </Box>
                                </Flex>
                            </Box>

                        </SwiperSlide>

                    </Swiper>

                </Box>
                <Box css={{
                    width: "80%",
                    margin: "0 auto",
                    border:"1px solid rgba(15,129,255,0.8)"
                    // backgroundColor:"rgba(15,129,255,0.8)"
                }}>
                    <Flex >
                        <Box css={{
                            width:"60%"
                        }}>
                           <Box css={{
                               margin:"$6"
                           }}>
                               <Text style="h2" css={{
                               color:"#0F81FF"
                           }}>The Future of Parallel</Text></Box>
                            <Flex css={{
                                marginLeft:"$6"
                            }}>
                                <Box css={{
                                    width:"10px",
                                    height:"100px",
                                    backgroundColor:"#0F81FF"
                                }}>

                                </Box>
                                <Flex direction="column" justify="center" css={{
                                    marginLeft:"$6"
                                }}>
                                    <Text style="h5" color="subtle">Avatars: 001</Text>
                                    <Text style="h3"  css={{
                                        color:"#FFF"
                                    }}>
                                        The nexus of expanding Parallel experiences.
                                    </Text>

                                </Flex>
                            </Flex>
                          <Box css={{
                              margin:'$6'
                          }}>
                              <Text style="h7" color="subtle">In the expanding Parallel Universe, Avatars serve as the gateway to new experiences. Avatars are first in line to try our new activities and activations as they premiere. With our catalogue of games looking to expand in the coming years you can expect to see your Avatars as main characters within those experiences and maybe even within storytelling elements. Welcome to the new frontier.</Text>
                          </Box>
                        </Box>
                        <Box css={{
                            padding:"$4"
                        }}>
                            <Image src={P6} alt="" width={600}/>
                        </Box>
                    </Flex>
                </Box>

                <Box css={{
                    width: "80%",
                    margin: "0 auto",
                    border:"1px solid rgba(15,129,255,0.8)"
                    // backgroundColor:"rgba(15,129,255,0.8)"
                }}>
                    <Flex >

                        <Box css={{
                            padding:"$4"
                        }}>
                            <Image src={P6} alt="" width={600}/>
                        </Box>

                        <Box css={{
                            width:"60%"
                        }}>
                            <Flex css={{
                                margin:"$6"
                            }}>
                                <Box css={{
                                    width:"10px",
                                    height:"100px",
                                    backgroundColor:"#0F81FF"
                                }}>

                                </Box>
                                <Flex direction="column" justify="center" css={{
                                    marginLeft:"$6"
                                }}>
                                    <Text style="h5" color="subtle">Avatars: 002</Text>
                                    <Text style="h3"  css={{
                                        color:"#FFF"
                                    }}>
                                        Unlock Exclusive Benefits
                                    </Text>

                                </Flex>

                            </Flex>
                            {/**/}
                            <Box css={{
                                margin:"$6"
                            }}>
                                <Text style="h2" css={{
                                    color:"#0F81FF"
                                }}>Unlock Exclusive Benefits</Text></Box>
                            <Box css={{
                                margin:'$6'
                            }}>
                                <Text style="h7" color="subtle">

                                    Your Avatar will represent you not only in Parallel TCG, but in other upcoming games and online activations around the Parallel Universe. Owning an Avatar comes with a variety of exclusive benefits including a boost to PRIME earnings when winning within the TCG*, access to our latest activations and explorations as they premier and a Casting Call to have your Avatar featured in future comics or Parallel media.
                                  <br></br>  * Boost is applied when winning with Avatar equipped and matches the winning Parallel's deck.
                                </Text>
                            </Box>
                        </Box>

                    </Flex>
                </Box>
            </Box>
        </>
    )
}

type TrendingCollectionsSchema =
    paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
    paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
    ssr: {
        trendingMints: TrendingMintsSchema
        trendingCollections: TrendingCollectionsSchema
        featuredCollections: TrendingCollectionsSchema
    }
}> = async ({ params, res }) => {
    const chainPrefix = params?.chain || ''
    const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
    // init BaseApi
    // supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
    // DefaultChain
    // console.log("请求的路劲",reservoirBaseUrl)
    const headers: RequestInit = {
        headers: {
            'x-api-key': process.env.RESERVOIR_API_KEY || '',
        },
    }
    let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            sortBy: 'volume',
        }

    const trendingCollectionsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending/v1`,
        trendingCollectionsQuery,
        headers
    )
    let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            sortBy: 'sales',
        }

    const featuredCollectionsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending/v1`,
        featuredCollectionQuery,
        headers
    )

    let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            type: 'any',
        }

    const trendingMintsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending-mints/v1`,
        trendingMintsQuery,
        headers
    )

    const promises = await Promise.allSettled([
        trendingCollectionsPromise,
        featuredCollectionsPromise,
        trendingMintsPromise,
    ]).catch((e) => {
        console.error(e)
    })

    const trendingCollections: Props['ssr']['trendingCollections'] =
        promises?.[0].status === 'fulfilled' && promises[0].value.data
            ? (promises[0].value.data as Props['ssr']['trendingCollections'])
            : {}
    const featuredCollections: Props['ssr']['featuredCollections'] =
        promises?.[1].status === 'fulfilled' && promises[1].value.data
            ? (promises[1].value.data as Props['ssr']['featuredCollections'])
            : {}

    const trendingMints: Props['ssr']['trendingMints'] =
        promises?.[1].status === 'fulfilled' && promises[1].value.data
            ? (promises[1].value.data as Props['ssr']['trendingMints'])
            : {}

    res.setHeader(
        'Cache-Control',
        'public, s-maxage=120, stale-while-revalidate=180'
    )
    return {
        props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
    }
}
export default Banner